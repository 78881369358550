import { reactive, ref } from "vue";
import { list } from "@/api/parking/parkingLotBillRuleApi";
import useMessage from "@/hooks/useMessage";
const useParkingBillRule = () => {
  const { showErr } = useMessage();
  const loading = ref(false);
  const dataList = reactive({ list: [] });
  const total = ref(0);

  const parkingBillRuleVo = reactive({
    name: "",
    parkingFreeTime: "",
    dayFeeMax: "",
    leaveFreeTime: "",
    gracePeriod: "", //宽限时长

    parkingLotBillRuleSubId: "",
    ruleType: "0",  //收费规则类型，0：24小时统一标准收费规则  1：晚间收费规则 ，默认为0，为1时启用晚间收费规则
    dayRuleType: "0", //白天收费类型, {0:固定时段,1:分时段}'
    billRuleDayPeriods: [{duration: "", fee: ""}],
    dayTimeSection: "",
    dayFeeSection: "",
    nightTimeStart: "",
    nightTimeEnd: "",
    nightTimeSection: "",
    nightFeeSection: "",
    nightFeeMax: ""
  });

  const columns = [
    {
      title: "序号",
      align: "center",
      width: "80px",
      customRender: ({ index }) => {
        return index + 1;
      },
    },
    {
      title: "收费规则名称",
      align: "center",
      dataIndex: "name",
    },
    {
      title: "免费时长",
      align: "center",
      dataIndex: "parkingFreeTime",
      slots: {customRender: "parkingFreeTime"}
    },
    {
      title: "离场免费时长",
      align: "center",
      dataIndex: "leaveFreeTime",
      slots: {customRender: "leaveFreeTime"}
    },
    {
      title: "宽限时长",
      align: "center",
      dataIndex: "gracePeriod",
      slots: {customRender: "gracePeriod"}
    },
    {
      title: "24小时封顶值",
      align: "center",
      dataIndex: "dayFeeMax",
      slots: {customRender: "dayFeeMax"}
    },
    {
      title: "收费规则类型",
      align: "center",
      dataIndex: "ruleType",
      slots: {customRender: "ruleType"}
    },
    {
      title: "操作",
      align: "center",
      width: "240px",
      slots: { customRender: "operation" },
    },
  ];
  const money = m => {
    if (typeof m !== "undefined") {
      return (m / 100).toFixed(2);
    }
    return "";
  };
  const timeF = m => {
    if (typeof m !== "undefined") {
      return (m / 60).toFixed(0);
    }
    return "";
  };
  const moneyZ = (val) => {
    if (typeof val !== "undefined") {
      return val * 100;
    }
    return 0;
  };
  const timeZ = (val) => {
    if (typeof val !== "undefined") {
      return val * 60;
    }
    return 0;
  };
  const loadData = async (page, pageSize) => {
    loading.value = true;
    try {
      let res = await list(params, page, pageSize);
      dataList.list = res.data;
      total.value = res.total;
      loading.value = false;
    } catch (e) {
      loading.value = false;
      await showErr(e);
    }
  };

  const params = reactive({
    name: "",
  });
  return {
    loading,
    params,
    dataList,
    total,
    columns,
    money,
    timeF,
    moneyZ,
    timeZ,
    loadData,
    parkingBillRuleVo,
  };
};
export default useParkingBillRule;

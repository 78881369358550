import axios from "axios";

export const allBillRules = () => {
  return axios.get("/parking/billRule/listAll");
};

export const list = (params, page, pageSize) => {
  return axios.get("/parking/billRule/list", {
    params: {
      ...params,
      page,
      pageSize,
    },
  });
};

export const save = vo => {
  return axios.post("parking/billRule/add", vo);
};

export const detail = id => {
  return axios.get(`parking/billRule/${id}/detail`);
};

export const edit = (id, vo) => {
  return axios.put(`/parking/billRule/${id}/edit`, vo);
};

export const deleteparkinBillRule = id => {
  return axios.delete(`/parking/billRule/${id}/del`);
};